import React from 'react';
import { Typography } from '@material-ui/core';
import { PracticeFeatures } from './environment';

const GENERAL_PROVIDER_PHONE_NUMBER = '615-475-5059';
const buildPhoneNumber = (): React.ReactElement => (
  <span style={{ whiteSpace: 'pre' }}>{GENERAL_PROVIDER_PHONE_NUMBER}</span>
);
const buildEmailLink = (emailAddress: string): React.ReactElement => (
  <a href={`mailto:${emailAddress}`} target="_blank" rel="noopener noreferrer">
    {emailAddress}
  </a>
);

// tslint:disable-next-line typedef
const messages = {
  logout: 'You have been logged out.',
  deactivated: (
    <>
      <Typography variant="h3">User Verification Required</Typography>
      <p>
        Your account access has been deactivated. Reactivating your account takes approximately 5 minutes and can be
        done by contacting axialHealthcare’s Clinical Consult Services team at 615-475-5059 or{' '}
        <a href="mailto:accesssupport@axialhealthcare.com">accesssupport@axialhealthcare.com</a>. axialHealthcare
        requires that all users are reverified at least every 6 months to maintain security measures.
      </p>
    </>
  ),
  pageNotFound: 'The page could not be found.',
  invalidModule: 'This page is not available for this combination of healthplan and provider.',
  declinedAttestation:
    'axialHealthcare takes protection of patient health information seriously and cannot allow ' +
    'access to this information without proper authorization. To view this information, please ' +
    'return once the necessary authorization has been obtained and select "Agree" on the attestation ' +
    'window to gain access.',
  hasServerError: 'Something went wrong. Please try again later.',
  contactUs: (
    <>
      If you have questions about this tool, please contact axialHealthcare's Clinical Consult Services team at{' '}
      {buildPhoneNumber()} or {buildEmailLink('providersupport@axialhealthcare.com')}.
    </>
  ),
  rim: {
    scoreNotAvailable:
      'Only providers with a minimum of 7 total patients between both cohorts will generate a monthly RIM score.',
    faq: [
      {
        title: 'Why are enhancements to RIM scores being made?',
        text:
          'A new change has been implemented for RIM scores to better align with quality measures and ' +
          'methodologies, including PQA, HEDIS, STARS, and other national guidelines. ' +
          'This adjustment reflects the labeled indication of buprenorphine for the treatment of persistent, ' +
          'severe pain that requires long-term opioid therapy when alternative treatments are insufficient. ' +
          'Going forward, any patient receiving a prescription for buprenorphine for pain will automatically ' +
          'be included in the Chronic Opioid Use section of the RIM report, regardless of whether they ' +
          'were opioid-naïve prior to the prescription.',
      },
      {
        title: 'How does axialHealthcare define the chronic opioid use cohort?',
        text:
          'Patients assigned to the chronic opioid use cohort have at least 90 days of opioid use within 120 days, ' +
          'have had recurring fills of the same opioid and quantity within a 120-day period, ' +
          'or have received a prescription for buprenorphine for pain within 120 days.',
      },
      {
        title: 'How does axialHealthcare define the acute opioid use cohort?',
        text:
          'Patients assigned to the acute opioid use cohort are those that have been prescribed ' +
          'an initial opioid prescription after an opioid naive period of 45 days.',
      },
      {
        title: 'What informs cohort assignment?',
        text:
          'Patient cohort assignment is informed by analysis of medical and pharmacy claims ' +
          'data received from a health plan a provider is contracted with.',
      },
      {
        title: 'Are there any excluded patient cohorts?',
        text:
          'Yes, patients receiving medication-assisted treatment, active cancer treatment, ' +
          'or palliative care treatment as well as those with a prior cancer diagnosis are ' +
          'excluded from the methodology.',
      },
      {
        title: 'Why don’t I have a RIM score?',
        text:
          'To generate a RIM score, providers must have a minimum of 7 patients assigned to ' +
          'the chronic opioid use or acute opioid use cohorts within the 90 days of the reporting period.',
      },
      {
        title: 'What is my 3-month RIM score average?',
        text:
          'Your RIM score average is an assessment into your opioid prescribing quality ' +
          'spanning the most recent, consecutive 3-month rolling reporting period. A new ' +
          '3-month period will begin on the first day of each calendar month.',
        featureFlag: PracticeFeatures.providerStratMovingAverage,
      },
      {
        title: "Why don't I see my RIM score average?",
        text:
          'Only those providers that have generated a RIM score for all three of the most ' +
          'recent reporting months will generate a RIM score average. To generate a monthly ' +
          'RIM score, a provider must have a minimum of 7 total patients between both the ' +
          'chronic and acute opioid cohorts. Providers without a 3-month average can still ' +
          'gain valuable insights into their opioid prescribing quality by viewing their ' +
          'monthly score.',
        featureFlag: PracticeFeatures.providerStratMovingAverage,
      },
    ],
  },
};

export { messages };
